// Has errors
export default {
  data: () => ({
    error: null,
    code: null,
    message: null,
  }),

  methods: {
    resetErrors() {
      this.error = null;
      this.code = null;
      this.message = null;
    },

    storeError(error) {
      this.error = error;

      if (error.response) {
        this.code = error.response.status;
        this.message = error.response.data.message;
      }
    },
  },
};
